<template>
  <div class="setting_form clear">

    <add-custom-widget-modal ref="custom_widget_modal" v-if="isShowModal"></add-custom-widget-modal>

    <div class="white_box  ">
      <div class="box_inner">
        <div class="box_head ">
          <div class="d-flex align-items-center">
            <div class="left">
              <h2>3rd Party / Custom Widgets</h2>
            </div>
            <div class="right ml-auto more_width">

              <button @click.prevent="showModal()"
                      class="btn---cta btn-blue with-shadow btn-round   btn-bold">
                <span>Add Custom Widget Code</span>
                <span class="icon_right">
                               <i
                                 class="circle_icon d-flex align-items-center justify-content-center  fal fa-plus  "></i>
                            </span>
              </button>

            </div>
          </div>
          <div class="left mt-3">
            <p>You can add 3rd party widgets here to use them in your campaigns. This adds infinite possibilities
              for you to use any type of custom or 3rd party widget you want.</p>
            <p> For example, You can use optin forms from tools like Optinmonster, Sumo,
              Poptin, Convertful or even your own lead forms. You can add chat widgets (Intercom, Drift or any
              other),
              Video embeds (Youtube, Vimeo, Vooplayer, Wistia etc.), Quizzes ( BrandQuiz, Outgrow, SurveyMonkey
              etc.) or just
              about any other widget you can normally use on your website.</p>
          </div>
          <div class="d-flex align-items-center mt-2">
            <div class="left">
              <div class="mr-2 search_input_block no_animation" :class="{ active_input: payload.search }">
                <input placeholder="Search custom widgets" :value="payload.search" @input="debounceSearch" type="text"
                       class="search_input"/>
                <i class="fal fa-search"></i>
              </div>
            </div>
            <div class="right ml-auto">
              <b-dropdown
                class="ml-2 d-inline-block dropdown-menu-right default_style_dropdown checkbox_dropdown results_dropdown"
                :no-caret="true"
                ref="resultsDropdown">
                <div class="dropdown_header rounded_shape d-flex align-items-center  "
                     slot="button-content">
                  <span class="text">{{ resultsText }}</span>
                  <span class="arrow_icon ml-auto">
                                                <i class=" fal fa-angle-down ml-3"></i>
                                             </span>
                </div>
                <ul class="inner results_filter_dropdown">
                  <li :key="index" v-for="(item,index) in [10, 20, 30, 50, 100]" class="list_item_li"
                      @click="results(item)">
                    {{ item }} Results
                    <i v-if="payload.limit==item" class="far fa-check"></i>
                  </li>
                </ul>
              </b-dropdown>
            </div>
          </div>
        </div>
        <div class="box_content">
          <div class="table_container ">
            <table class="table campaign_box list-content list-content">
              <thead class="">
              <tr>
                <th class="" style="width: 230px;text-align: left;">Name</th>

                <th class="" style="width: 200px;">Created</th>

                <th style="width: 200px;text-align: right;">Actions</th>
              </tr>
              </thead>
              <tbody class="list-content-body">
              <tr v-if="getCustomWidgetLoader">
                <td colspan="7">
                  <clip-loader
                    :size="'20px'"
                    :color="color"
                  ></clip-loader>
                </td>
              </tr>
              <template v-else-if="getCustomWidgetList.length > 0">
                <tr v-for="(code,index) in getCustomWidgetList">

                  <td style="text-align: left;">
                    {{ code.name }}
                  </td>

                  <td class="created_at">
                    {{ code.created_at | relativeCaptilize }}
                  </td>

                  <td class="table-action ">
                    <a style="display: inline-block;" @click.prevent="previewWidget([code._id])">
                      <i v-tooltip="'Preview'" class="far fa-eye"
                         aria-hidden="true"></i>
                    </a>

                    <i v-tooltip="'Edit'" @click.prevent="editWidget(code)" class="far fa-pencil "
                       aria-hidden="true"></i>
                    <i v-tooltip="'Remove'" class="far fa-trash "
                       @click.prevent="deleteWidget({id: code._id,index:index})"
                       aria-hidden="true"></i>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="9">
                    <p class="no-rows"><i class="fab fa-bandcamp" aria-hidden="true"></i>{{getFilteredMessage('Custom Widget',payload.search, 'All Time', payload.archive) }}</p>
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <template
        v-if="!getCustomWidgetLoader &&
                pagination.totalItems > pagination.limit">
        <pagination
          :clickedMethod="paginateCustomWidgets"
          :resultCount="pagination.totalItems"
          :itemsPerPage="pagination.limit"
          :currentPage="pagination.page"
          :totalPagesNumber="pagination.totalPages"
        ></pagination>
      </template>
    </div>
  </div>
</template>

<script>
// require component
import AddCustomWidgetModal from './dialogs/AddCustomWidgetModal'
// require styles

import { mapGetters, mapActions } from 'vuex'
import { DARK_PURPLE_LOADER_COLOR } from '../../../../common/constants'
import Pagination from '@/misc/Pagination.vue'

export default ({
  components: {
    AddCustomWidgetModal,
    Pagination
  },
  data () {
    return {
      size: '17px',
      color: DARK_PURPLE_LOADER_COLOR,
      colorWhite: '#ffffff',
      isShowModal: false,
      resultsText: 'Results Per Page',
      payload: {
        search: '',
        page: 1,
        limit: 10,
        sort: 'created_desc'
      },
      pagination: {}
    }
  },
  async created () {
    this.getSiteDetails
    const params = this.getParamsFromURL()
    this.payload.search = params.search
    this.payload.page = params.page
    this.payload.limit = params.limit
    if (this.payload.search && this.payload.search.length > 0) this.payload.page = 1
    if (this.payload.limit && this.payload.limit > 10) {
      this.resultsText = this.payload.limit + ' Results'
      this.payload.page = 1
    }
    const pagination = await this.fetchWidgets(this.payload)
    this.setCustomWidgetPagination(pagination)
  },
  methods: {
    ...mapActions([
      'deleteWidget',
      'previewWidget',
      'fetchWidgets'
    ]),
    showModal () {
      this.isShowModal = true
      setTimeout(() => {
        this.showCodeModal()
      }, 100)
    },
    closeModal () {
      this.isShowModal = false
      setTimeout(() => {
        this.isShowModal = true
      }, 100)
    },
    editWidget (code) {
      this.isShowModal = true
      setTimeout(() => {
        this.editCustomWidget(code)
      }, 100)
    },
    debounceSearch (event) {
      this.$router.replace({ query: { ...this.$route.query, search: event.target.value } })
      this.$router.replace({ query: { ...this.$route.query, page: 1 } })
      this.payload.search = event.target.value
      this.payload.page = 1
      clearTimeout(this.debounce)
      this.debounce = setTimeout(async () => {
        const pagination = await this.fetchWidgets(this.payload)
        this.setCustomWidgetPagination(pagination)
      }, 600)
    },
    async results (value) {
      this.resultsText = value + ' Results'
      this.$refs.resultsDropdown.hide()
      this.$router.replace({ query: { ...this.$route.query, limit: value } })
      this.$router.replace({ query: { ...this.$route.query, page: 1 } })
      this.payload.limit = value
      this.payload.page = 1
      const pagination = await this.fetchWidgets(this.payload)
      this.setCustomWidgetPagination(pagination)
    },
    setCustomWidgetPagination (pagination) {
      this.pagination = {
        page: pagination.current_page,
        totalItems: pagination.total,
        limit: pagination.per_page,
        totalPages: pagination.last_page
      }
    },
    async paginateCustomWidgets (page) {
      this.$router.replace({ query: { ...this.$route.query, page: page } })
      this.payload.page = page
      this.pagination.page = page
      const pagination = await this.fetchWidgets(this.payload)
      this.setCustomWidgetPagination(pagination)
    }
  },
  computed: {
    ...mapGetters([
      'getCustomWidgetList',
      'getCustomWidgetLoader'
    ])
  }
})
</script>

<style scoped>
.search_input {
  max-width: 100% !important;
}
</style>
